<template>
  <v-dialog v-model="mostrar" max-width="600" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat
        ><h3>Enlace de votación</h3></v-toolbar>
      <v-container>
        <v-container v-if="!asistente">
          <a>{{ mensaje }}</a>
        </v-container>
        <v-container v-else>
          <div v-if="enlace">
            <a>Presione el siguiente enlace para acceder al sistema de votación:</a>
            <br><br>
            <a :href="enlace">{{enlace}}</a>
          </div>
          <div v-else>
            <br><a>{{ mensaje }}</a>
          </div>
        </v-container>
      </v-container>

      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { cargarEnlaceSesion } from "./sesionesLive.service";

export default {
  name: "enlaceSesion",
  props: ["mostrar", "idSesion"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["httpURL","userAdmin","codigoUsuario"]),
  },
  data() {
    return {
      loading: false,
      asistente: false,
      enlace: "",
      mensaje: ""
    };
  },
  created() {
    this.cargarEnlace();
  },
  methods: {
    async cargarEnlace() {
      try {
        this.loading = true;
        const serverResponse = await cargarEnlaceSesion(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.asistente = serverResponse.respuesta.asistente;
          this.mensaje = serverResponse.respuesta.mensaje;
          this.enlace = serverResponse.respuesta.enlace;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>