<template>
  <v-dialog v-model="mostrar" max-width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dark dense flat
        ><h3>{{ titulo }}</h3></v-toolbar
      >
      <v-container>
        <div v-html="contenido"></div>
        <v-img
          v-if="usuarioMaster"
          width="200"
          :src="`${httpURL}/sesion/invitacion/qr/${idSesion}/${usuarioMaster}`"
        ></v-img>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" small outlined @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { cargarInvitacionSesion } from "./sesionesLive.service";

export default {
  name: "invitacionSesion",
  props: ["mostrar"],
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["httpURL"]),
  },
  data() {
    return {
      loading: false,
      idSesion: null,
      titulo: "",
      contenido: "",
      usuarioMaster: "",
    };
  },
  created() {
    this.cargarInvitacion();
  },
  methods: {
    async cargarInvitacion() {
      try {
        this.idSesion = this.$route.params.idSesion;
        this.loading = true;
        const serverResponse = await cargarInvitacionSesion(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          this.$notificarError(serverResponse.mensaje);
        } else {
          this.titulo = serverResponse.invitacion.titulo;
          this.contenido = serverResponse.invitacion.contenido;
          this.usuarioMaster = serverResponse.usuarioMaster;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>
