<template>
  <v-main>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-toolbar elevation="1" color="secondary" dark>
              <v-btn icon @click="regresar">
                <v-icon>mdi-arrow-left-bold</v-icon>
              </v-btn>
              <v-toolbar-title class="text-h4">
                {{ sesion.nombre }}</v-toolbar-title
              >
            </v-toolbar>
            <v-col md="12">
              <v-card flat>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">
                      {{ fechaLocal }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-h6">
                      {{ consejo.nombre }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action v-if="userAdmin || roleUsuario.admin">
                    <v-progress-circular
                      :rotate="-90"
                      :size="50"
                      :width="10"
                      :value="porcentajeAsistencia"
                      color="primary"
                    >
                      {{ asistentes.length }}
                    </v-progress-circular>
                  </v-list-item-action>
                  <v-list-item-action v-if="userAdmin">
                    <v-chip
                      small
                      :outlined="!quorum"
                      :dark="quorum"
                      :color="quorum ? 'green darken-3' : 'red darken-1'"
                      >Quórum - {{ textoQuorum }}</v-chip
                    >
                    <v-chip
                      small
                      :outlined="!votacionEconomica"
                      :dark="votacionEconomica"
                      :color="
                        votacionEconomica ? 'green darken-3' : 'red darken-1'
                      "
                      >Votación - {{ textoVot }}</v-chip
                    >
                  </v-list-item-action>
                </v-list-item>
                <v-list-item style="justify-content: flex-end" v-if="userAdmin || roleUsuario.admin">
                    <v-chip outlined large><v-icon left>mdi-account-circle-outline</v-icon>Consejeros {{asistentes.length}} de {{ this.consejeros.length }}</v-chip>
                </v-list-item>
                
                  <v-list-item v-if="roleUsuario.consejero">
                    <v-btn color="primary" small @click="verInvitacion = true">Ver invitación</v-btn>
                  </v-list-item>
                  <v-list-item v-if="userAdmin && !sesion.invitacion.textoInvitacion">
                    <v-btn color="primary" small @click="enviarInvitacion = true">Generar invitación</v-btn>
                  </v-list-item>
                  <!--HACER UNA VISTA PARA QUE EL QUE LA HIZO VEA QUIENES FALTAN DE APROBAR LA INVITACION-->
                  <v-list-item v-if="userAdmin && sesion.invitacion.textoInvitacion">
                    <v-btn color="primary" small @click="enviarInvitacion = true">Revisar invitación</v-btn>
                  </v-list-item>
                  <!--Enviar Invitacion solo cuando ya esta aprobada-->
                  <v-list-item v-if="userAdmin && sesion.invitacion.textoInvitacion">
                    <v-btn color="primary" small @click="enviarInvitacion = true">Enviar invitación</v-btn>
                  </v-list-item>
                  
                  <v-list-item v-if="roleUsuario.consejero">
                    <v-btn color="secondary lighten-1" small @click="verEnlace = true">Liga de votación</v-btn>
                  </v-list-item>
                <!-- FINAL -->
              </v-card>
            </v-col>

            <invitacionSesionComponent
              v-if="verInvitacion"
              :mostrar="verInvitacion"
              @cancelar="verInvitacion = false"
            />

            <enlaceSesionComponent 
              v-if="verEnlace"
              :mostrar="verEnlace"
              :idSesion="idSesion"
              @cancelar="verEnlace = false"
            />

            <asistentesSesionComponent
              v-if="roleUsuario.admin"
              :idSesion="idSesion"
              :consejeros="consejeros"
              :asistentes="asistentes"
              :asistencias="asistencias"
              :permisoEnviarCorreos="permisoEnviarCorreos"
              :permisoEliminarAsistentes="permisoEliminarAsistentes"
              @asistenciaAgregada="asistenciaAgregada"
              @asistenciaModificada="asistenciaModificada"
              @asistenteEliminado="asistenteEliminado"
              :envioCorreos="sesion.enviarCorreo"
              :nombreSesion="sesion.nombre"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";
import { getSesionService } from "./sesionesLive.service";
import { getAsistentesService } from "../administrar/administrar.service";
import { DateTime } from "luxon";

import asistentesSesionComponent from "../administrar/asistentes/asistentesSesion.vue";
import invitacionSesionComponent from "./invitacionSesion.vue";
import enlaceSesionComponent from "./enlaceSesion.vue";
import { ordenarObjetosAsc } from "../../helpers/ordenador";

export default {
  name: "sesionLive",
  components: {
    asistentesSesionComponent,
    invitacionSesionComponent,
    enlaceSesionComponent,
  },
  computed: {
    ...mapGetters(["userAdmin", "sessionToken"]),
    porcentajeAsistencia() {
      const porcentaje = Math.round(
        (this.asistentes.length * 100) / this.consejeros.length
      );
      return porcentaje;
    },
    quorum() {
      //50% +1
      let minimo = Math.ceil(this.consejeros.length / 2);
      minimo++;
      return this.asistentes.length >= minimo;
    },
    votacionEconomica() {
      //2/3 del consejo
      let minimo = Math.ceil((this.consejeros.length / 3) * 2);
      return this.asistentes.length >= minimo;
    },
    textoQuorum() {
      let minimo = Math.ceil(this.consejeros.length / 2);
      minimo++;
      return minimo;
    },
    textoVot() {
      let minimo = Math.ceil((this.consejeros.length / 3) * 2);
      return minimo;
    },
    asistentes() {
      let asistentes = [];
      if(this.asistencias.length){
      this.asistencias.forEach((asistencia) => {
        const consejero = this.consejeros.find((x) => {
          if (x.usuarioMaster && x.usuarioMaster._id.toString() == asistencia.usuarioMaster.toString()) return true;
          if(x.suplente.usuarioMaster && x.suplente.usuarioMaster._id){
            if (x.suplente.usuarioMaster._id == asistencia.usuarioMaster) return true
          }
        });

        const asistente = this.getAsistente(consejero, asistencia);
        if (asistente) asistentes.push(asistente);
      });
      }
      if (this.votacionEnlaces.length != 0) {
        asistentes.forEach((asistente) => {
          const indexEnlace = this.votacionEnlaces.findIndex(
            (x) => asistente.idConsejero === x.consejero
          );
          if (indexEnlace != -1) {
            asistente.enlaceVotacion = this.votacionEnlaces[indexEnlace].enlace;
            asistente.voto=this.votacionEnlaces[indexEnlace].voto
          }
        });
      }

      asistentes = ordenarObjetosAsc(asistentes, "_id");
      let numero = 1;
      asistentes.forEach((asistente) => {
        asistente.numero = numero++;
      });
      return asistentes;
    },
  },
  watch: {
    loadAsistentes() {
      if(this.loadAsistentes) this.cargarAsistentes();
    }
  },
  data() {
    return {
      loading: false,
      idSesion: null,
      fechaLocal: "",
      roleUsuario: {},
      sesion: {invitacion:{textoInvitacion:null}},
      consejo: {},
      consejeros: [],
      asistencias: [],
      dependencias: [],
      abrirCerrarSesion: false,
      verInvitacion: false,
      votacionEnlaces: [],
      asistentesModificados: [],
      interval: 0,
      loadAsistentes: false,
      verEnlace: false,
      permisoEnviarCorreos: false,
      permisoEliminarAsistentes: false,
      enviarInvitacion: false
    };
  },
  mounted() {
    if(this.sessionToken){
      this.idSesion = this.$route.params.idSesion;
      this.cargarSesion();
    }
  },
  methods: {
    async cargarSesion() {
      try {
        this.loading = true;
        const serverResponse = await getSesionService(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.sesion = serverResponse.sesion;
          this.asistencias = serverResponse.asistencias;
          this.consejo = serverResponse.consejo;
          this.consejeros = serverResponse.consejeros;
          this.roleUsuario = serverResponse.roleUsuario;
          this.permisoEnviarCorreos = serverResponse.roleUsuario.permisoEnviarCorreos;
          this.permisoEliminarAsistentes = serverResponse.roleUsuario.permisoEliminarAsistentes;
          this.loadAsistentes = this.roleUsuario.admin;
          this.votacionEnlaces = serverResponse.votacionEnlaces;
          this.setFecha();
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    setFecha() {
      let fecha = DateTime.fromISO(this.sesion.fecha);
      this.fechaLocal = fecha.toLocaleString(DateTime.DATETIME_MED) + " hrs.";
    },
    sesionAbierta(data) {
      this.sesion.abierta = data.sesionAbierta;
      this.abrirCerrarSesion = false;
    },
    asistenciaAgregada(data) {
      this.asistencias.push(data.asistencia);
    },
    getAsistente(consejero, asistencia) {
      if (consejero) {
        let asistente = {
          dependencia: consejero.dependencia,
          divisionDepto: consejero.divisionDepto,
          hora: DateTime.fromISO(asistencia.fecha).toLocaleString(
            DateTime.TIME_24_SIMPLE
          ),
          correoEnviado: asistencia.correoEnviado,
        };

        if (consejero.usuarioMaster._id == asistencia.usuarioMaster) {
          asistente.calidad = "Propietario";
          asistente.idConsejero = consejero._id;
          asistente.categoria = consejero.categoria;
          asistente.funcionario = consejero.usuarioMaster.nombre;
          asistente.codigo = consejero.usuarioMaster.codigo;
          asistente.correoEnviado = asistencia.correoEnviado;
          asistente.correo = consejero.usuarioMaster.correo;
        } else {
          asistente.calidad = "Suplente";
          asistente.idConsejero = consejero._id;
          asistente.categoria = consejero.suplente.categoria;
          asistente.funcionario = consejero.suplente.usuarioMaster.nombre;
          asistente.codigo = consejero.suplente.usuarioMaster.codigo;
          asistente.correoEnviado = asistencia.correoEnviado;
          asistente.correo = consejero.suplente.usuarioMaster.correo;
        }
        return asistente;
      } else {
        this.asistentesModificados.push(asistencia.usuarioMaster);
      }
      return null;
    },
    administradorAgregado(data) {
      this.administradores.push(data.administrador);
    },
    dependenciaAsignada(data) {
      const index = this.administradores.findIndex(
        (x) => x._id == data.idAdministrador
      );
      if (index >= 0)
        this.administradores[index].dependencias.push(data.idDependencia);
    },
    dependenciaQuitada(data) {
      const index = this.administradores.findIndex(
        (x) => x._id == data.idAdministrador
      );
      if (index >= 0) {
        const indexDependencia = this.administradores[
          index
        ].dependencias.findIndex((x) => x == data.idDependencia);
        this.administradores[index].dependencias.splice(indexDependencia, 1);
      }
    },
    administradorEliminado(data) {
      const index = this.administradores.findIndex(
        (x) => x._id == data.idAdministrador
      );
      if (index >= 0) this.administradores.splice(index, 1);
    },
    asistenteEliminado(data) {
      const index = this.asistencias.findIndex(
        (x) => x._id == data.idAsistencia
      );
      if (index >= 0) this.asistencias.splice(index, 1);
    },
    asistenciaModificada(data) {
      const index = this.asistencias.findIndex((x) => x._id == data._id);
      this.asistencias[index].correoEnviado = data.correoEnviado;
    },
    cargarAsistentes() {
      //TODO: Descomentar esto y quitarle el async a cargarAsistentes()
      this.interval = setInterval(async () => {
        if(this.$route.params.idSesion){
          try {
            const serverResponse = await getAsistentesService(this.idSesion);
            this.$validateResponse(serverResponse);
            if (!serverResponse.ok) {
              throw new Error(serverResponse.mensaje);
            } else {
              this.asistencias = serverResponse.asistencias;
              this.votacionEnlaces = serverResponse.votacionEnlaces;
              this.consejeros = serverResponse.consejeros;
            }
          } catch (error) {
            this.loading = false;
            this.$notificarError(error);
          }
        } else {
          clearInterval(this.interval);
        }
      }, 5000);
    },
    regresar(){
      clearInterval(this.interval);
      this.$router.go(-1);
    },
    actualizarTexto(texto){
      this.sesion.invitacion.textoInvitacion=texto
    }
  },
  };

</script>